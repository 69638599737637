<template>
  <div>
    <a-layout-header
      class="bg-white position-fixed text-start"
      v-if="getBrowser == 'pc'"
    >
      <router-link to="/" class="logo">
        <cs-icon
          name="icon-logo1"
          type="symbol"
          style="
            height: 30px;
            width: auto;
            margin-left: -80px;
            margin-top: 10px;
          "
        />
      </router-link>
      <div class="d-flex float-end">
        <nav class="nav-bar">
          <router-link to="/" 
          :class="{ active: this.$route.path.includes('/home/index') }"
            >首页</router-link
          >
          <router-link
            to="/product/index"
            style="font-family: 经典雅黑; margin-right: 20px"
            :class="{ active: isActiveProductPath }"
            >产品服务
            <cs-icon
              name="icon-biaoqianlan_HOT"
              type="symbol"
              size="25"
              style="position: absolute; margin-top: 13px"
            />
          </router-link>
          <!-- <router-link
          to="/learn/index"
          v-if="userInfo.userName"
          :class="{ active: this.$route.path.includes('/learn/') }"
          >学习中心</router-link
        > -->
          <router-link
            to="/home/about"
            :class="{ active: this.$route.path === '/home/about' }"
            >关于我们</router-link
          >
          <a href="/ucs/home" target="_blank" v-if="isLogin">工作台</a>
        </nav>
        <!-- <div class="user-menu" v-if="userInfo.userName">
        <a-popover overlayClassName="user-menu-popover" placement="bottomLeft">
          <template slot="content">
            <div class="d-flex user-menu-info">
              <div>
                <a-avatar :size="40" :src="userInfo.headUrl" />
              </div>
              <div style="padding-left: 12px">
                <a-tooltip placement="top">
                  <template
                    slot="title"
                    v-if="userInfo.realName && userInfo.realName.length > 6"
                  >
                    <span>{{ userInfo.realName }}</span>
                  </template>
                  <label>{{ userInfo.realName | intercept(6, true) }}</label>
                </a-tooltip>
                <br />
                <span>{{ userInfo.userName | encryText }}</span>
              </div>
            </div>
            <div class="user-menu-action">
              <a @click="logoutClick">
                <cs-icon name="icon-zhuxiao" size="16" />
                退出登录
              </a>
            </div>
          </template>
          <router-link to="/user/index">
            <a-avatar
              :src="userInfo.headUrl"
              style="
                margin-top: -5px;
                border: 1px solid #e3e3e3;
                margin-right: 10px;
                margin-left: 50px;
              "
          /></router-link>
        </a-popover>
      </div> -->
        <a-button v-if="!isLogin" class="btn-login" @click="loginClick">
          登录/注册
        </a-button>
      </div>
    </a-layout-header>
    <div class="header" v-if="getBrowser == 'phone'">
        <router-link to="/" class="logo">
          <cs-icon
            name="icon-logo1"
            type="symbol"
            style="
              height: 30px;
              width: auto;
              margin-left: -70px;
              margin-top: 6px;
            "
          />
      </router-link>
      <img
        src="../../assets/nav.png"
        alt=""
        @click="clickChange"
      />
      <van-popup
        v-model="showSelect"
        position="top"
        @click="showSelect = false"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
        v-if="getBrowser == 'phone'"
      >   
         <div style="border-bottom:1px solid #eee;height:50px;width:100%;display:flex;justify-content:space-between;">
          <img src="../../assets/titlelog.png" alt="" style="height:28px;width:120px;margin-left:12px;margin-top:10px;">
          <img src="../../assets/cancle.png" alt="" style="height:18px;margin-left:12px;margin-top:15px;text-align:right" @click="showSelect = false">
         </div>
          
            <router-link   to="/"  style="border-bottom:1px solid #eee;">
            <van-cell title="首页" is-link >  </van-cell>
             </router-link>
      
            <router-link to="/product/index" style="border-bottom:1px solid #eee;">
               <van-cell title="产品服务" is-link > </van-cell>
            </router-link>
         
       
              <router-link to="/home/about" style="border-bottom:1px solid #eee;">
                  <van-cell title="关于我们" is-link> </van-cell>
              </router-link>
         
      </van-popup>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isLogin: false,
      showSelect: false,
    };
  },
  computed: {
    ...mapState({ userInfo: (state) => state.store.userInfo }),
    isActiveProductPath() {
      return this.$route.path.indexOf("/product/") === 0;
    },
  },
  inject: ["loadUserInfo"],
  created() {
    // this.loadUserInfo();
    this.isLogin = !!this.api.storage.getCookie("UCS-AUTHEN-TOKEN");
  },
  methods: {
    loginClick() {
      const { location } = window;
      location.href = location.origin + "/ucs";
    },
    clickChange() {
      this.showSelect = true;
    },
    logoutClick() {
      this.api.service
        .uac_oapi_sso_loginOut()
        .then(async (res) => {
          await this.api.toast(res, 0);
        })
        .finally(() => {
          this.api.logout();
        });
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 320px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height:48px;
    position: fixed;
    top: 0;
    left: 0;
    background:white;
    z-index: 1000;
    img {
      margin-right:20px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .ant-layout-header {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 50px;
    line-height: 50px;
    box-shadow: 0 0.5px 1px #ccc;
    margin-bottom: 20px;
    .nav-bar {
      a {
        font-size: 16px;
        color: #333333;
        padding: 13px 0;
        margin-left: 40px;
        &.active,
        &:hover {
          color: @primary-color;
          border-bottom: 2px solid @primary-color;
        }
      }
    }
    .user-menu {
      .nickname {
        font-size: 16px;
        color: #333333;
      }
    }
    .btn-login {
      margin-left: 40px;
      margin-top: 9px;
      width: 103px;
      height: 32px;
      border: 1px solid #202121;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      font-weight: 500;
      color: #202121;
    }
  }
}
</style>
<style lang="less">
.user-menu-popover {
  width: 195px;
  .ant-popover-inner-content {
    padding: 0;
    .user-menu-info {
      margin: 0 20px 10px 20px;
      padding: 20px 0;
      border-bottom: 1px solid #fafafa;
      .ant-avatar-image {
        border: 1px solid #e3e3e3;
      }
      label {
        font-size: 14px;
        color: #333;
      }
      span {
        font-size: 12px;
        color: #666;
      }
    }
    .user-menu-action {
      a {
        margin: 0;
        display: flex;
        padding: 10px 20px;
        list-style: none;
        font-size: 14px;
        align-items: center;
        color: #333333;
        .iconfont {
          margin-right: 10px;
        }
        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
}
.van-cell{
  color: #202121;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>